<template>
    <div class="app-container">
            <div class="h-100">
                <div class="h-100 no-gutters row">
                    <div class="d-none d-lg-block col-lg-4">
                        <div class="slider-light">
                            <div class="slick-slider">
                                <div>
                                    <div class="position-relative h-100 d-flex justify-content-center align-items-center bg-plum-plate" tabindex="-1">
                                        <div class="slide-img-bg" style="background-image: url('../assets/images/originals/city.jpg');"></div>
                                        <div class="slider-content"><h3>Praktis</h3>
                                            <p>Satu nomor WhatsApp bisa diakses oleh banyak Admin</p></div>
                                    </div>
                                </div>
                                <div>
                                    <div class="position-relative h-100 d-flex justify-content-center align-items-center bg-premium-dark" tabindex="-1">
                                        <div class="slide-img-bg" style="background-image: url('../assets/images/originals/citynights.jpg');"></div>
                                        <div class="slider-content"><h3>Lebih Cepat</h3>
                                            <p>Membalas pesan Customer lebih cepat dengan fitur template</p></div>
                                    </div>
                                </div>
                                <div>
                                    <div class="position-relative h-100 d-flex justify-content-center align-items-center bg-sunny-morning" tabindex="-1">
                                        <div class="slide-img-bg" style="background-image: url('../assets/images/originals/citydark.jpg');"></div>
                                        <div class="slider-content"><h3>Analisis Chat</h3>
                                            <p>Pantau bagaimana Admin bekerja</p></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="h-100 d-flex bg-white justify-content-center align-items-center col-md-12 col-lg-8">
                        <div class="mx-auto app-login-box col-sm-12 col-md-10 col-lg-9">
                            <div >
                                <img src="@/assets/logo.png" style="width:200px;heigh: 200px">
                            </div>
                            <h3 class="mb-0">
                                <span>Login</span></h3>
                            <h6 class="mt-3">Belum punya akun ? <router-link to="register" class="text-primary">Daftar disini sekarang</router-link></h6>
                            <div class="divider row"></div>
                            <div>
                                <form class="user" @submit.prevent="login">
                                    <div class="form-row">
                                        <div class="col-md-6">
                                            <div class="position-relative form-group"><label for="exampleEmail" class="">Email</label>
                                                <input name="email" id="exampleEmail" placeholder="Email here..." type="email" class="form-control" v-model="form.email">
                                                <small class="text-danger" v-if="errors.email">{{errors.email[0]}}</small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="position-relative form-group"><label for="examplePassword" class="">Password</label>
                                                <input name="password" id="examplePassword" placeholder="Password here..." type="password" class="form-control" v-model="form.password">
                                                <small class="text-danger" v-if="errors.password">{{errors.password[0]}}</small>
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div class="divider row"></div>
                                    <div class="d-flex align-items-center">
                                        <div class="ml-auto"><a href="javascript:void(0);" class="btn-lg btn btn-link"></a>
                                            <button class="btn btn-primary btn-lg">Login</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script type="text/javascript">
    import axios from 'axios'

    export default {
        created() {
            const storeToken = localStorage.getItem('access_token');
            if (storeToken){
                this.$router.push({name: 'chat'})
            }
        },

        data(){
        return {
            form: {
                email: null,
                password: null
            },
            errors: {}
            }
        },
        methods: {
            login(){
                axios.post(this.BASE_URL + 'auth/signin', this.form)
                .then(res => {
                    console.log(res.data.data.accessToken)
                    if (res.data.success) {
                        localStorage.setItem('username', res.data.data.username)
                        localStorage.setItem('email', res.data.data.email)
                        localStorage.setItem('access_token', res.data.data.accessToken)
                        localStorage.setItem('role_id', res.data.data.role_id)
                        this.$router.push({name: 'chat'})
                        self.Toast.fire({
                                icon: 'success',
                                title: 'Login Success'
                            })
                    } else {
                        self.Toast.fire({
                                icon: 'error',
                                title: 'Login Error'
                            })
                    }
                    
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        this.Toast.fire({
                            icon: 'warning',
                            title: 'Silakan cek kembali email dan password'
                        });
                        
                        localStorage.clear();
                    } else {
                        this.Toast.fire({
                            icon: 'error',
                            title: error.response.data.errors
                        });
                    }
            })
        
        }
    }
    }
  
</script>