<template>
  <div class="app-container app-theme-white body-tabs-shadow fixed-header fixed-content fixed-sidebar" >
        <Header/>
        <div class="app-main">
           <Sidebar/>
            <div class="app-main__outer" style="positon:fixed;height:100%">
                <router-view/>
            </div>
            
        </div>
        
    </div>
</template>
<script>
  import Sidebar from './Sidebar.vue'
  import Header from './Header.vue'

  export default {
    name: 'App',
    components: {
      Sidebar,
      Header
    },
    created() {
      const storeToken = localStorage.getItem('access_token');
      if (!storeToken){
          this.$router.push({name: 'login'}).catch(()=>{});
      }
    },
  }
</script>


