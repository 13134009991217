<template>
<div class="app-main__inner">
    <div class="app-page-title">
        <div class="page-title-wrapper">
            <div class="page-title-heading">
                <div class="page-title-icon">
                    <i class="pe-7s-camera icon-gradient bg-ripe-malin"></i>
                </div>
                <div>Akun WhatsApp
                    <div class="page-title-subheading">Hubungkan Aplikasi WhatsApp Anda dengan platform kami</div>
                </div>
            </div>
        </div>
    </div>  
    <div class="row">
        <div class="col-sm-12 col-md-7 col-lg-8">
            <div class="mb-3 card">
                <div class="card-header-tab card-header">
                    <div class="card-header-title font-size-lg text-capitalize font-weight-normal">Petunjuk Scan QR Code</div>
                    
                </div>
                <div class="pt-0 card-body">
                    <div class="scroll-area-md">
                        <div class="scrollbar-container">
                            <div class="p-4">
                                <div class="vertical-time-simple vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                                    <div class="vertical-timeline-item dot-danger vertical-timeline-element">
                                        <div>
                                            <span class="vertical-timeline-element-icon bounce-in"></span>
                                            <div class="vertical-timeline-element-content bounce-in">
                                                <h4 class="timeline-title">Buka Aplikasi WhatsApp di smartphone Anda</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="vertical-timeline-item dot-warning vertical-timeline-element">
                                        <div>
                                            <span class="vertical-timeline-element-icon bounce-in"></span>
                                            <div class="vertical-timeline-element-content bounce-in">
                                                <h4 class="timeline-title">Kemudian pilih menu Linked Devices</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="vertical-timeline-item dot-success vertical-timeline-element">
                                        <div>
                                            <span class="vertical-timeline-element-icon bounce-in"></span>
                                            <div class="vertical-timeline-element-content bounce-in">
                                                <h4 class="timeline-title">Scan QR Code disamping ini dengan aplikasi WhatsApp Anda</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="vertical-timeline-item dot-primary vertical-timeline-element">
                                        <div>
                                            <span class="vertical-timeline-element-icon bounce-in"></span>
                                            <div class="vertical-timeline-element-content bounce-in">
                                                <h4 class="timeline-title">WhatsApp Anda sudah terhubung dengan cek-wa.com
                                                    
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-5 col-lg-4">
            <div class="mb-3 card">
                <div class="card-header-tab card-header">
                    <div class="card-header-title font-size-lg text-capitalize font-weight-normal">QR Code</div>
                     <div class="btn-actions-pane-right text-capitalize">
                        <button class="btn btn-danger" @click="showLogoutDialog()" v-show="isConnect">Logout</button>
                    </div>
                </div>
                <div class="p-0 card-body">
                    <div id="chart-radial"></div>
        
                    <div class="widget-content pt-0 w-100" >
                        <div class="widget-content-outer text-center mt-4">
                            <div class="widget-subheading" v-show="isInit">Mohon menunggu ...</div>
                            <qrcode-vue :value="value" :size="size" level="H" v-show="!isConnect && !isInit" v-cloak/>
                        </div>
                        <div class="widget-content-outer text-center mt-4" v-show="isConnect">
                            <div class="widget-subheading">WhatsApp sudah terhubung</div>
                            <router-link to="/chat"><button class="btn btn-success mt-3">Buka Chat Sekarang</button></router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
         <div class="col-sm-12 col-md-7 col-lg-8">
            <div class="mb-3 card">
                <div class="card-header-tab card-header">
                    <div class="card-header-title font-size-lg text-capitalize font-weight-normal">API Token</div>
                </div>
                <div class="p-0 card-body">
                    <div id="chart-radial"></div>
                    <div class="widget-content pt-0 w-100" >
                        <div class="widget-content-outer text-center mt-4" v-show="isConnect">
                            
                            <div v-if="!apiToken">
                                <button class="btn btn-primary mt-3" @click="getTokenApi()">Request Api Key</button>
                            </div>

                            <div v-if="apiToken">
                                <input type="hidden" :value="apiToken" id="text-copy"/>
                                <div class="widget-subheading">{{apiToken}}</div>
                                <button class="btn btn-primary mt-3" @click="copy()">Copy API Key</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<style>
   [v-cloak] {
       display: none;
  }
</style>

<script>
import QrcodeVue from 'qrcode.vue'
import axios from 'axios'
import Swal from 'sweetalert2';
export default {
    data() {
      return {
        value: '',
        size: 250,
        isConnect: false,
        isInit: true,
        apiToken: null
      }
    },
    components: {
      QrcodeVue,
    },
    created() {
        const storeToken = localStorage.getItem('access_token');
        if (!storeToken){
            this.$router.push({name: 'login'})
        } else {
            this.statusSession()
        }
    },
    methods: {
        statusSession() {
            this.interval = setInterval(() => {
                var url = this.BASE_URL + 'status-session'
                axios.get(url,  {
                    headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': localStorage.getItem('access_token')
                    }
                })
                .then(({data}) => {
                    if (data.success) {
                        if (data.data != null) {
                            if (data.data.status != 'authenticated') {
                                this.createSession();
                                this.isConnect = false;
                                this.isInit = false;
                            } else {
                                this.isConnect = true;
                                this.isInit = false;
                            }
                        }
                    } else {
                        this.createSession();
                    }
                    
                })
                .catch(error => {
                    clearInterval( this.interval )
                    console.log(error)
                    this.Toast.fire({
                        icon: 'error',
                        title: error
                    })

                })
            }, 5000)
        },
        createSession() {
            var url = this.BASE_URL + 'add-session'
            axios.post(url, {}, {
                headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('access_token')
                }
            })
            .then(({data}) => {
                if (data.success) {
                    this.value = data.data.qr;
                    console.log(this.value);
                    // this.getMessageTemplates()
                } else {
                    this.Toast.fire({
                        icon: 'error',
                        title: 'Error simpan message template'
                    })
                }

            })
            .catch(error => {
                this.Toast.fire({
                    icon: 'error',
                    title: error
                })

            })
        },
        showLogoutDialog() {
            Swal.fire({
                title: 'Anda yakin?',
                text: "",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.logout()
                }
            })
        },
        logout() {
            var url = this.BASE_URL + 'delete-session'
            axios.post(url, {}, {
                headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('access_token')
                }
            })
            .then(({data}) => {
                if (data.success) {
                    this.Toast.fire({
                        icon: 'success',
                        title: 'Sukses logout akun WhatsApp'
                    })
                } else {
                    this.Toast.fire({
                        icon: 'error',
                        title: 'Error simpan message template'
                    })
                }

            })
            .catch(error => {
                this.Toast.fire({
                    icon: 'error',
                    title: error
                })

            })
        },
        getTokenApi() {
            const url = this.BASE_URL + 'get-token-api'
            axios.get(url,  {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': localStorage.getItem('access_token')
                }
            })
            .then(({data}) => {
                if (data.success) {
                    this.apiToken = data.data.token;
                }
            })
            .catch(error => {
                this.Toast.fire({
                    icon: 'error',
                    title: error
                })
            })
        },
        copy() {
            let noToCopy = document.querySelector('#text-copy')
            noToCopy.setAttribute('type', 'text') 
            noToCopy.select()
            try {
                document.execCommand('copy');
                 noToCopy.setAttribute('type', 'hidden')
                window.getSelection().removeAllRanges()
               
                alert('Sukses copy');
            } catch (err) {
                alert('Oops, unable to copy');
            }

            
        },
    },
    destroyed: function(){
        clearInterval( this.interval )
    },
    
}
</script>