<template>
<div class="app-main__inner">
    <div class="app-page-title">
        <div class="page-title-wrapper">
            <div class="page-title-heading">
                <div class="page-title-icon">
                    <i class="pe-7s-camera icon-gradient bg-ripe-malin"></i>
                </div>
                <div>Edit Label
                    <div class="page-title-subheading">Anda dapat mengubah label</div>
                </div>
            </div>

        </div>
    </div>  
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-body">
                    <form @submit.prevent="updateLabel" enctype="multipart/form-data">
                        <div class="position-relative form-group">
                            <label for="exampleEmail" class="">Label</label>
                            <input type="text" class="form-control" v-model="form.name">
                        </div>
                        <button class="mt-1 btn btn-primary" type="submit">Simpan</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
      return {
        value: '',
        size: 250,
        isConnect: false,
        isInit: true,
        labels: [],
        form: {
            id: null,
            name: null
        }
      }
    },
    created() {
        const storeToken = localStorage.getItem('access_token');
        if (!storeToken){
            this.$router.push({name: 'login'})
        } else {
            this.form.id = this.$route.params.id;
            this.getLabel();
        }
    },
    methods: {
        getLabel() {
            const url = this.BASE_URL + 'get-label-by-id?id=' + this.form.id
            axios.get(url, {
                headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('access_token')
                }
            })
            .then(({data}) => {
                this.form.name = data.data[0].name;
            })
            .catch(error => {
                this.Toast.fire({
                    icon: 'error',
                    title: error
                })

            })
        },
        updateLabel() {
            var url = this.BASE_URL + 'update-label'
            axios.post(url, this.form, {
                headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('access_token')
                }
            })
            .then(({data}) => {
                if (data.success) {
                    this.Toast.fire({
                        icon: 'success',
                        title: 'Sukses simpan'
                    })
                    this.$router.push({name: 'label'})
                } else {
                    this.Toast.fire({
                        icon: 'error',
                        title: 'Error simpan message template'
                    })
                }

            })
            .catch(error => {
                this.Toast.fire({
                    icon: 'error',
                    title: error
                })

            })
        }
    }
}
</script>