<template>
    <div class="app-sidebar sidebar-shadow">
        <div class="app-header__logo">
            <div class="logo-src"></div>
            <div class="header__pane ml-auto">
                <div>
                    <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
                        <span class="hamburger-box">
                            <span class="hamburger-inner"></span>
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <div class="app-header__mobile-menu">
            <div>
                <button type="button" class="hamburger hamburger--elastic mobile-toggle-nav">
                    <span class="hamburger-box">
                        <span class="hamburger-inner"></span>
                    </span>
                </button>
            </div>
        </div>
        <div class="app-header__menu">
            <span>
                <button type="button" class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                    <span class="btn-icon-wrapper">
                        <i class="fa fa-ellipsis-v fa-w-6"></i>
                    </span>
                </button>
            </span>
        </div>    
        <div class="scrollbar-sidebar">
            <div class="app-sidebar__inner">
                <ul class="vertical-nav-menu">
                    <li class="app-sidebar__heading">Menu Utama</li>
                        <!-- <li>
                            <router-link to="/hello" active-class="mm-active">
                                <i class="metismenu-icon pe-7s-ball">
                                </i>Analisis
                            </router-link>
                        </li> -->
                        
                        <li v-show="isShow">
                            <router-link to="/whatsapp-account" active-class="mm-active">
                                <i class="metismenu-icon pe-7s-refresh-2">
                                </i>Akun WhatsApp
                            </router-link>
                        </li>
                        <li v-show="isShow">
                            <router-link to="/analytic" active-class="mm-active">
                               <i class="metismenu-icon pe-7s-display1">
                                </i>Analytics
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/chat" active-class="mm-active">
                                <i class="metismenu-icon pe-7s-chat">
                                </i>Live Chat
                            </router-link>
                        </li>
                        <li v-show="isShow">
                            <router-link to="/team" active-class="mm-active">
                                <i class="metismenu-icon pe-7s-users">
                                </i>User
                            </router-link>
                        </li>
                        <li v-show="isShow">
                            <router-link to="/message-template" active-class="mm-active">
                                <i class="metismenu-icon pe-7s-note2">
                                </i>Template Pesan
                            </router-link>
                        </li>
                        <li v-show="isShow">
                            <router-link to="/label" active-class="mm-active">
                                <i class="metismenu-icon pe-7s-ticket">
                                </i>Label
                            </router-link>
                        </li>
                        <li>
                            <a href="#" active-class="mm-active" @click="showLogoutDialog()">
                                <i class="metismenu-icon pe-7s-power">
                                </i>Logout
                            </a>
                        </li>
                </ul>
            </div>
        </div>
    </div> 
</template>
<script>
    import Swal from 'sweetalert2';
    export default {
        data() {
            return {
                isShow: true
            }
        },
        created() {
            const roleId = localStorage.getItem('role_id');
            if (roleId == 2) {
                this.isShow = true;
            } else {
                this.isShow = false;
            }
            console.log(this.isShow);
            
        },
        methods: {
            showLogoutDialog() {
                Swal.fire({
                    title: 'Anda yakin logout?',
                    text: "",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes'
                    }).then((result) => {
                    if (result.isConfirmed) {
                        localStorage.clear();
                        this.Toast.fire({
                            icon: 'warning',
                            title: 'Sukses logout'
                        });
                        this.$router.push({name: 'login'})
                        
                    }
                })
            },
        }
    }
</script>