<template>
<div class="app-main__inner">
    <div class="app-page-title">
        <div class="page-title-wrapper">
            <div class="page-title-heading">
                <div class="page-title-icon">
                    <i class="pe-7s-camera icon-gradient bg-ripe-malin"></i>
                </div>
                <div>Edit Template Pesan
                    <div class="page-title-subheading">Anda dapat mengubah template pesan</div>
                </div>
            </div>

        </div>
    </div>  
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-body">
                    <form @submit.prevent="updateMessageTemplate" enctype="multipart/form-data">
                        <div class="position-relative form-group">
                            <label for="exampleEmail" class="">Judul</label>
                            <input type="text" class="form-control" v-model="form.title">
                        </div>

                        <div class="position-relative form-group">
                            <label for="exampleText" class="">Message</label>
                            <textarea rows="5" class="form-control" v-model="form.message"></textarea>
                        </div>
                        <div class="position-relative form-group">
                            <label for="exampleText" class="">File upload</label>
                            <div class="col-md-12 col-lg-4" v-if="form.media_url">
                                <ul class="todo-list-wrapper list-group list-group-flush">
                                <li class="list-group-item">
                                    <div class="todo-indicator bg-success"></div>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left flex2">
                                                <img :src="getImgUrl(url+form.media_url)" style="width:100px;height:100px;">
                                            </div>
                                            <div class="widget-content-right ml-3">
                                                <!-- <button class="border-0 btn-transition btn btn-outline-danger"> -->
                                                    <i class="fa fa-trash-alt" @click="deleteImage()"></i>
                                                <!-- </button> -->
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                
                            </ul>
                            </div>
                            <input type="file" ref="file" class="form-control-file" v-on:change="onFileChange" v-else>
                                <small class="form-text text-muted">{{fileName}}</small>
                        </div>
                        
                       

                        <button class="mt-1 btn btn-primary" type="submit">Simpan</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
      return {
        form: {
            id: null,
            title: null,
            message: null,
            media_url: null
        },
        url: null
      }
    },
    created() {
        const storeToken = localStorage.getItem('access_token');
        if (!storeToken){
            this.$router.push({name: 'login'})
        } else {
            this.form.id = this.$route.params.id
            this.getMessageTemplateById(this.form.id);
        }
    },
    methods: {
        getMessageTemplateById(id) {
            const url = this.BASE_URL + 'get-message-template-by-id?id=' + id
            axios.get(url, {
                headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('access_token')
                }
            })
            .then(({data}) => {
                this.form.title = data.data.messageTemplates[0].title
                this.form.message = data.data.messageTemplates[0].message

                this.form.media_url = data.data.messageTemplates[0].media_url
                this.url = data.data.url
                console.log(data);
            })
            .catch(error => {
                this.Toast.fire({
                    icon: 'error',
                    title: error
                })

            })
        },
        updateMessageTemplate() {
            var url = this.BASE_URL + 'update-message-template'
            axios.post(url, this.form, {
                headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('access_token')
                }
            })
            .then(({data}) => {
                if (data.success) {
                    this.Toast.fire({
                        icon: 'success',
                        title: 'Sukses simpan'
                    })
                    this.$router.push({name: 'message-template'})
                } else {
                    this.Toast.fire({
                        icon: 'error',
                        title: 'Error simpan message template'
                    })
                }

            })
            .catch(error => {
                this.Toast.fire({
                    icon: 'error',
                    title: error
                })

            })
        },
        getImgUrl(src) {
            return src;
        },
        onFileChange(e){
                console.log(e.target.files[0]);
                this.file = e.target.files[0];
                let formData = new FormData();
                formData.append('file', this.file);
                var url = this.BASE_URL + 'upload-file'

                axios.post(url, formData, {
                    headers: {
                        'content-type': 'multipart/form-data',
                        'x-access-token': localStorage.getItem('access_token')
                    }
                })
                .then(({data}) => {
                    console.log(data)
                    this.fileName = 'Success upload :' + data.data.originalName;
                    this.form.media_url = data.data.path + data.data.fileName
                    // Toast.fire({
                    //     icon: 'success',
                    //     title: 'Sukses upload'
                    // })
                    
                })
                .catch(error => {
                    console.log(error)
                    // Toast.fire({
                    //     icon: 'error',
                    //     title: error.response.data.message
                    // })
                });
        },
        deleteImage() {
            this.form.media_url = null
            this.Toast.fire({
                        icon: 'warning',
                        title: 'Gampar terhapus'
            });
        }
    }
}
</script>