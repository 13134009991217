<template>
<div class="app-main__inner">
    <div class="app-page-title">
        <div class="page-title-wrapper">
            <div class="page-title-heading">
                <div class="page-title-icon">
                    <i class="pe-7s-camera icon-gradient bg-ripe-malin"></i>
                </div>
                <div>Edit Team
                    <div class="page-title-subheading">Anda dapat mengubah akun Team</div>
                </div>
            </div>

        </div>
    </div>  
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-body">
                    <form @submit.prevent="updateTeam" enctype="multipart/form-data">
                        <div class="position-relative form-group">
                            <label for="exampleEmail" class="">Username</label>
                            <input type="text" class="form-control" v-model="form.username">
                        </div>
                         <div class="position-relative form-group">
                            <label for="exampleEmail" class="">Email</label>
                            <input type="email" class="form-control" v-model="form.email" readonly>
                        </div>
                        <div class="position-relative form-group">
                            <label for="role" class="">Role</label>
                            <select class="mb-2 form-control" v-model="form.role_id">
                                <option v-for="item in roles" :key="item.id" v-bind:value="item.id" :selected="form.role_id === item.id">{{item.name}}</option>
                            </select>
                        </div>
                        <div class="position-relative form-group">
                            <label for="exampleWaPribadi" class="">Nomor WA Pribadi</label>
                            <input type="text" class="form-control" v-model="form.private_number">
                        </div>
                         <div class="position-relative form-group">
                            <label for="exampleEmail" class="">Password</label>
                            <input type="password" class="form-control" v-model="form.password">
                        </div>
                         <div class="position-relative form-group">
                            <label for="exampleEmail" class="">Konfirmasi Password</label>
                            <input type="password" class="form-control" v-model="form.password_confirm">
                        </div>
                        <button class="mt-1 btn btn-primary" type="submit">Simpan</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
      return {
        value: '',
        size: 250,
        isConnect: false,
        isInit: true,
        labels: [],
        roles: [],
        form: {
            id: null,
            username: null,
            email: null,
            password: null,
            password_confirm: null,
            role_id: null,
            private_number: null
        }
      }
    },
    created() {
        const storeToken = localStorage.getItem('access_token');
        if (!storeToken){
            this.$router.push({name: 'login'})
        } else {
            this.form.id = this.$route.params.id;
            this.getDataTeam();
            this.getRoles();
        }
    },
    methods: {
        getDataTeam() {
            const url = this.BASE_URL + 'get-user-team-by-id?id=' + this.form.id
            axios.get(url, {
                headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('access_token')
                }
            })
            .then(({data}) => {
                this.form.id = data.data[0].id;
                    this.form.id = data.data[0].id;
                    this.form.username = data.data[0].username;
                    this.form.email = data.data[0].email;
                    this.form.role_id = data.data[0].role_id;
                    this.form.private_number = data.data[0].private_number;
                
            })
            .catch(error => {
                this.Toast.fire({
                    icon: 'error',
                    title: error
                })

            })
        },
        updateTeam() {
            var url = this.BASE_URL + 'update-user-team'
            axios.post(url, this.form, {
                headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('access_token')
                }
            })
            .then(({data}) => {
                if (data.success) {
                    this.Toast.fire({
                        icon: 'success',
                        title: 'Sukses simpan'
                    })
                    this.$router.push({name: 'team'})
                } else {
                    this.Toast.fire({
                        icon: 'error',
                        title: data.message
                    })
                }

            })
            .catch(error => {
                this.Toast.fire({
                    icon: 'error',
                    title: error
                })

            })
        },
        getRoles() {
            var url = this.BASE_URL + 'get-roles'
            axios.get(url, {
                headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('access_token')
                }
            })
            .then(({data}) => {
                if (data.success) {
                    this.roles = data.data
                } else {
                    this.Toast.fire({
                        icon: 'error',
                        title: data.message
                    })
                }

            })
            .catch(error => {
                this.Toast.fire({
                    icon: 'error',
                    title: error
                })

            })
        }
    }
}
</script>