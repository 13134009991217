<template>

<div class="app-main__inner">
    
    <div class="row">
        <!-- Chat  -->
        <div class="col-sm-12 col-lg-3" :class="{ 'd-none d-lg-block': isChatHide}">
            <!-- Search -->
            <div class="card-hover-shadow-2x mb-2 card">
                 <div class="input-group">
                    <div class="input-group-prepend" @click="search()" >
                        <div class="input-group-text">
                            <i class="fa fa-times" v-if="isSearch" ></i>
                            <i class="fa fa-search" v-else></i>
                        </div>
                    </div>
                    <input placeholder="Search..." type="text" class="form-control" v-model="formSearch.keyword" :readonly="isSearch">
                </div>
            </div>

            <!-- List Chat  -->
            <div class="card-hover-shadow-2x mb-3 card">
                <!-- Search Chat  -->
                <div class="card-header-tab card-header">
                    <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
                        {{title_chat}}
                    </div> 
                    <div class="btn-actions-pane-right text-capitalize actions-icon-btn">
                        
                        <div class="btn-group dropdown">
                            <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="btn-icon btn-icon-only btn btn-link">
                                <i class="pe-7s-filter btn-icon-wrapper"></i>
                            </button>
                            
                            <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu dropdown-menu-right scroll-area-lg">
                                <h6 tabindex="-1" class="dropdown-header">Filter Chat by Label</h6>
                                <button type="button" @click="setFilterLabel(item.id)" tabindex="0" class="dropdown-item" v-for="item in allLabels" :key="item.id"><span>{{item.name}}</span></button>
                                <div class="p-3 text-right">
                                    <button @click="clearFilter()" class="mr-2 btn-shadow btn-sm btn btn-warning">Clear Filter</button>
                                </div>
                            </div>
                        </div>
                     </div>                       
                </div>
                <!-- Main List Chat  -->
                <div class="scroll-area-lg">
                    <div class="scrollbar-container" >
                        <div class="p-2">
                            <p v-show="filterLabel.length > 0">Filter by Label</p>
                            <button v-show="filterLabel.length > 0" class="mb-2 mr-2 btn-pill btn-icon btn-icon-right btn btn-outline-success" v-for="item in filterLabel" :key="item.id">
                                {{item.name}} <i class="pe-7s-close-circle btn-icon-wrapper" @click="removeFilterLabel(item.id)"> </i>
                            </button>
                            <ul class="todo-list-wrapper list-group list-group-flush">
                                <li class="list-group-item" v-for="item in chats" :key="item.id">
                                    <div @click="getMessage(item.jid)">
                                        <div v-if="chat.jid == item.jid" class="todo-indicator bg-warning">
                                        </div>
                                        <div class="widget-content p-0" >
                                            <div class="widget-content-wrapper">
                                                <div class="widget-content-left mr-2">
                                                </div>
                                                <div class="widget-content-left">
                                                    <div><b>{{getNumberMask(item.jid)}}</b> - {{getNameMask(item.name)}}
                                                        <div class="badge badge-primary ml-2" v-show="item.hasLabel">#</div>
                                                    </div>
                                                    <div class="widget-subheading"><i>{{item.t}}</i></div>
                                                </div>

                                                <div class="widget-content-right">
                                                    <div class="badge badge-success mr-2" v-show="item.unread_count > 0">{{item.unread_count}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="d-block text-right card-footer">
                    <button class="btn btn-primary" @click="getDataFirstTime(false)">Load more</button>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-lg-9" v-if="chat.jid == null">
            <div class="card-hover-shadow-2x card" style="height: calc(100vh - 100px);">
                <div class="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center">
                    <div class="col-md-12 p-lg-5 mx-auto my-5">

                        <h1 class="display-4 font-weight-normal">Selamat Datang di cek-wa</h1>
                        <p class="lead font-weight-normal">Dengan cek-wa mirror, 1 nomor Whatsapp dapat dibuka oleh puluhan admin Anda. Balas chat costumer jadi lebih cepat !</p>
                        <a class="btn btn-outline-success" href="#">Video Tutorial</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- Message  -->
        <div class="col-sm-12 col-lg-6" :class="{ 'd-none d-lg-block': isMessageHide }" v-if="chat.jid != null">
            <div class="card-hover-shadow-2x mb-3 card" style="height: calc(100vh - 100px);">
                <!-- Chat Number -->
                <div class="card-header-tab card-header">
                    <div class="card-header-title font-size-lg text-capitalize font-weight-normal pt-4 pb-4" v-if="chat">
                        <i @click="back()" class="fa fa-fw mr-4" aria-hidden="true" title="Copy to use arrow-left" v-show="!isMessageHide"></i>{{chat.label}}
                    </div>
                    
                    <div class="btn-actions-pane-right text-capitalize actions-icon-btn">
                        <div class="btn-group dropdown d-block d-lg-none">
                            <button type="button" @click="openDetailChat()" class="border-0 btn-transition btn btn-link">
                                <i class="fas fa-info-circle"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- Message Detail -->

                <div class="message-template scroll-area-lg" style="height: 100vh;" v-chat-scroll>
                    <div class="scrollbar-container" v-chat-scroll>
                        <div class="p-2">
                            <div class="chat-wrapper">
                                <div v-for="item in messages" :key="item.id" >
                                    <div class="float-right" v-if="item.key_from_me" style="clear: both;">
                                        <div class="chat-box-wrapper chat-box-wrapper-right">
                                            <div>
                                                <div class="chat-box" style="white-space: pre-line">
                                                    <div>
                                                        <a :href="item.media_path" download="download" v-show="item.media_path && item.type == 'DOCUMENT'"></a>
                                                        <img :src="item.media_path" style="width: 250px;height: 100%;object-fit: cover;" v-show="item.media_path">
                                                        <button class="mb-2 mr-2 btn-transition btn btn-outline-secondary" v-show="item.type != 'TEXT' && item.media_path == null" @click="downloadMedia(item.key_id, item.key_id, item.type, false, null)">{{item.type}}
                                                        </button>
                                                        
                                                        <!-- quotedMessage -->
                                                        <button disabled="" class="mb-2 mr-2 btn-square btn btn-dashed btn-outline-info btn-block disabled text-left text-dark" v-if="item.quotedMessage != null">
                                                            <div>
                                                                <img :src="item.quoted_media_path" style="width: 250px;height: 100%;object-fit: cover;" v-show="item.quoted_media_path">
                                                                <button class="mb-2 mr-2 btn-transition btn btn-outline-secondary" v-if="item.quotedMessage.type != 'TEXT' && item.quoted_media_path == null" @click="downloadMedia(item.key_id, item.key_id, item.quotedMessage.type, true, item.quotedMessage.id)">{{item.quotedMessage.type}}</button>
                                                                <br>
                                                                <p style="width:250px; word-wrap:break-word;">{{item.quotedMessage.quote_text}}</p>
                                                            
                                                            </div>
                                                            
                                                        </button>
                                                        
                                                        
                                                        <p style="width:250px; word-wrap:break-word;">{{item.text}}</p>
                                                    </div>
                                                </div>
                                                <small class="opacity-6">
                                                    <!-- <i class="fa fa-calendar-alt mr-1"></i> -->
                                                    {{item.timestamp}}
                                                    |
                                                    <i class="fa fa-trash-alt mr-1 ml-2" style="color:red" @click="deleteMessage(item.key_id, item.t)"></i>
                                                   
                                                    <p v-if="item.username">dikirim oleh {{ item.username }}</p>

                                                    <i class="fas fa-tag ml-1" style="color:blue" @click="setUser(item.key_id, item.t)" v-else></i>


                                                    
                                                </small>
                                                <img src="@/assets/read.png" style="width:20px;heigh: 20px" v-if="item.message_status_id === 4">
                                                <img src="@/assets/sendonly.png" style="width:20px;heigh: 20px" v-else-if="item.status < 3">
                                                <img src="@/assets/unread.png" style="width:20px;heigh: 20px" v-else>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="chat-box-wrapper" v-else>
                                        <div>
                                            <div class="chat-box" style="white-space: pre-line">
                                                <div>
                                                    <a :href="item.media_path" download="download" v-show="item.media_path && item.type == 'DOCUMENT'"></a>
                                                    <img :src="item.media_path " style="width: 250px;height: 50%;object-fit: contain;" v-show="item.media_path && item.type == 'IMAGE'">
                                                    <button class="mb-2 mr-2 btn-transition btn btn-outline-secondary" v-show="item.type != 'TEXT' && item.media_path == null" @click="downloadMedia(item.key_id, item.key_id, false, null)">Download {{item.type}}
                                                    </button>

                                                    <!-- quotedMessage -->
                                                    <button disabled="" class="mb-2 mr-2 btn-square btn btn-dashed btn-outline-info btn-block disabled text-left text-dark" v-if="item.quotedMessage != null">
                                                        <div>
                                                            <img :src="item.quoted_media_path" style="width: 250px;height: 100%;object-fit: cover;" v-show="item.quoted_media_path">
                                                            <button class="mb-2 mr-2 btn-transition btn btn-outline-secondary" v-if="item.quotedMessage.type != 'TEXT' && item.quoted_media_path == null" @click="downloadMedia(item.key_id, item.key_id, item.quotedMessage.type, true, item.quotedMessage.id)">Download {{item.quotedMessage.type}}</button>
                                                            <br>
                                                        <p style="width:250px; word-wrap:break-word;">{{item.quotedMessage.quote_text}}</p>
                                                        </div>

                                                    </button>
                                                    <p style="width:250px; word-wrap:break-word;">{{item.text}}</p>
                                                </div>
                                            </div>
                                            <small class="opacity-6">
                                                    
                                                    {{item.timestamp}} | {{item.sender}}
                                                    <!-- <i class="fa fa-reply-all ml-2" style="color:blue"></i> -->
                                            </small>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
                    
                </div>

                <!-- Alert success upload -->
                <div class="card-footer" v-show="fileName">
                    <span class="text-success">Success upload : {{fileName}}</span>
                </div>

                <!-- Emoji -->
                <div class="card-footer" v-show="showDialog">
                    <VEmojiPicker
                        v-show="showDialog"
                        :style="{ width: '440px', height: '200' }"
                        labelSearch="Search"
                        lang="pt-BR"
                        @select="onSelectEmoji"
                        />
                </div>

                <!-- Button Option Message -->
                <div class="card-footer">
                    <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger" @click="$refs.file.click()">
                        <i class="pe-7s-cloud-upload btn-icon-wrapper"> </i>
                    </button>
                    <input type="file" ref="file" style="display: none" v-on:change="onFileChange">
                    <button class="mr-2 btn-icon btn-icon-only btn btn-outline-success" @click="toogleDialogEmoji">
                        <i class="pe-7s-smile btn-icon-wrapper"> </i>
                    </button>
                    <div class="btn-group dropdown">
                            <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="mr-2 btn-icon btn-icon-only btn btn-outline-primary">
                                <i class="fas fa-clipboard-list"></i>
                            </button>
                            <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu dropdown-menu-right scroll-area-lg">
                                <h6 tabindex="-1" class="dropdown-header">Template Pesan</h6>
                                <button @click="inputMessage(item.message, item.media_url)" type="button" tabindex="0" class="dropdown-item" v-for="item in messageTemplates" :key="item.id">{{item.title.substr(0, 23)}} ..<i class="fas fa-paperclip ml-2" v-show="item.media_url"></i></button>
                            </div>
                    </div>
                    <button class="mr-2 btn-icon btn-icon-only btn btn-outline-warning" @click="readMessage(chat.jid)">
                        <i class="pe-7s-look btn-icon-wrapper"> </i>
                    </button>
                </div>
                <!-- Text Input & Send Button -->
                <div class="card-footer">
                    <textarea name="text" id="exampleText" class="form-control" rows="2" v-model=form.message></textarea>
                    <button class="btn btn-success ml-3" @click="sendMessage()">Send</button>
                </div>        
            </div>
        </div>
        <!-- Detail Chat Info  -->
        <div class="col-sm-12 col-lg-3" :class="{ 'd-none d-lg-block': isDetailHide }" v-if="chat.jid != null">
            <div class="card-hover-shadow-2x mb-3 card" style="height: calc(100vh - 100px);">
                <div class="card-header-tab card-header">
                    <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
                        Detail Chat <i class="fa fa-window-close ml-4 d-block d-lg-none" aria-hidden="true" @click="closeDetailChat()"></i>
                    </div>  
                </div>
                <div class="scroll-area-lg" style="height: 100vh;">
                        <div class="scrollbar-container">
                            <div class="card-body text-center">
                                <p v-if="chat.name"> <i class="fa fa-user-circle mr-2" aria-hidden="true"></i> {{chat.name}}</p>
                               
                                <button class="mb-2 mr-2 btn btn-shadow btn-dashed btn-outline-success" v-if="chat.jid" @click="copyTestingCode()"> <input type="hidden" id="no-copy" v-if="chat.jid" :value="getNumber(chat.jid)"> {{getNumberMask(chat.jid)}}</button>
                                 
                            </div>
                            
                            <div class="card-body" v-show="labels.length > 0">
                                <h5 class="card-title">Label</h5>
                                <button  @click="setLabel(item.id)" class="btn-wide mb-2 mr-2 btn-icon btn-icon-right btn-pill btn btn-sm" :class="{ 'btn-primary': item.isSelected, 'btn-dashed btn-outline-primary': !item.isSelected }" style="overflow: scroll;overflow-x: scroll;overflow-y:hidden" v-for="item in labels" :key="item.id">{{item.name}}<i class="pe-7s-close-circle btn-icon-wrapper" v-if="item.isSelected"> </i></button>
                            </div>
                            <div class="card-body">
                                <h5 class="card-title">Assign to</h5>
                                <form @submit.prevent="setAssign()" enctype="multipart/form-data">
                                    <div class="position-relative form-group">
                                            <select class="mb-2 form-control" v-model="userAssign.assign_to" :disabled="isAssigned">
                                                <option v-for="item in teamUser" :key="item.id" v-bind:value="item.id">{{item.username}}</option>
                                            </select>
                                    </div>
                                    
                                    <div class="form-group">
                                        <textarea name="text" id="exampleText" class="form-control" rows="2" placeholder="Masukan catatan" v-model="userAssign.message" :readonly="isAssigned"></textarea>
                                    </div>
                                

                                    <button class="mr-2 btn-shadow btn-sm btn btn-warning" type="submit" v-show="!isAssigned">Assign Now</button>
                                </form>
                            </div>
                            <!-- <div class="card-body">
                                 <h5 class="card-title">Priority</h5>
                                 <form class="">
                                     <div class="position-relative form-check"><label class="form-check-label"><input type="checkbox" class="form-check-input" checked>High</label></div>
                                     <div class="position-relative form-check"><label class="form-check-label"><input type="checkbox" class="form-check-input">Normal</label></div>
                                     <div class="position-relative form-check"><label class="form-check-label"><input type="checkbox" class="form-check-input">Slow</label></div>
                                 </form>
                             </div> -->
                            
                        </div>
                    </div>
            </div>
        </div>
        
    </div>
</div>

</template>
<!-- template for the modal component -->


<script>
import axios from 'axios';
import moment from 'moment';
import { VEmojiPicker } from "v-emoji-picker";

export default {
  components: {VEmojiPicker },

    data() {
      return {
        chats: [],
        messages: [],
        value: '',
        size: 250,
        isConnect: false,
        isInit: true,
        param: {
            index: 0,
            cursorParam: null
        },
        form : {
            to: null,
            message: null,
            urlMedia: null,
            typeMedia: null,
            fileName: null
        },
        file: null,
        fileName: null,
        fileUrl: null,
        messageTemplates: [],
        download: {
            messageId: null,
            jid: null
        },
        labels: [],
        waLabel: {
            jid: null,
            labelId: null
        },
        baseUrl: null,
        chat: {
            jid: null
        },
        isChatHide: false,
        isMessageHide: true,
        isDetailHide: true,
        allLabels: [],
        filterLabel: [],
        filterLabelParam: [],
        teamUser: [],
        userAssign: {
            jid: null,
            assign_to: null,
            message: null,
            is_delete: false
        },
        isAssigned: false,
        showDialog: false,
        messageAction: {
            jid: null,
            message_id: null
        },
        formSearch: {
            keyword: null
        },
        isSearch: false,
        title_chat: "Live Chat"
      }
    },
    created() {
        const storeToken = localStorage.getItem('access_token');
        if (!storeToken){
            this.$router.push({name: 'login'})
        } else {
            this.getStatus();
        }
        window.addEventListener("resize", this.myEventHandler);
    },
    destroyed: function(){
        clearInterval( this.interval );
        clearInterval(this.getMessageInterval);
        window.removeEventListener("resize", this.myEventHandler);
    },
    methods: {
        back() {
            this.isMessageHide = true;
            this.isChatHide = false;
            this.isDetailHide = true;
        },
        connectWa() {
            var url = this.BASE_URL + 'connect'
            axios.get(url, {
                headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('access_token')
                }
            })
            .then(({data}) => {
                if (data.success) {
                    this.getStatus();
                }
            }).catch(error => {
                console.log(error);
            })
        },
        getStatus() {
            var url = this.BASE_URL + 'status-session'
            axios.get(url, {
                headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('access_token')
                }
            })
            .then(({data}) => {
                if (data) {
                    this.getDataFirstTime(true);
                    this.getAllLabels();
                }
            })
            .catch(error => {
                console.log(error);
                

            })
        },
        getDataFirstTime(isFirst) {
            this.param.index++;
            if (!isFirst) {
                this.param.cursorParam = this.cursor
            }
            var url = this.BASE_URL + 'get-chat'
            axios.post(url, this.param, {
                headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('access_token')
                }
            })
            .then(({data}) => {
                let self = this
                data.data.chats.forEach((obj) => {
                    obj.t = moment(moment.unix(obj.t).format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm').fromNow();
                    if (obj.jid != 'status@broadcast') {
                        self.chats.push(obj)
                    }
                    
                })
                this.cursor = data.data.cursor
                if (isFirst) {
                    this.getRealTimeDataInterval();
                }
                
            })
            .catch(error => {
                this.Toast.fire({
                    icon: 'error',
                    title: error
                })

            })
        },
        getRealTimeDataInterval() {
            this.interval = setInterval(() => {
                var url = this.BASE_URL + 'get-chat'
                this.param.cursorParam = null
                axios.post(url, this.param, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': localStorage.getItem('access_token')
                    }
                })
                .then(({data}) => {
                    let self = this
                    self.chats = []
                    data.data.chats.forEach((obj) => {
                        obj.t = moment(moment.unix(obj.t).format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm').fromNow();
                        if (obj.jid != 'status@broadcast') {
                            self.chats.push(obj)
                        }
                    })
                    this.cursor = data.data.cursor
                })
                .catch(error => {
                    this.Toast.fire({
                        icon: 'error',
                        title: error
                    })

                })
            }, 8000)
        },
        getNumber(str) {
            if (str.includes('@g.us')) {
                return 'GROUP';
            } else {
                let numberStr = str.replace('@s.whatsapp.net', '')
                return numberStr;
            }
        },
        getNumberMask(str) {
            if (str.includes('@g.us')) {
                return 'GROUP';
            } else {
                let numberStr = str.replace('@s.whatsapp.net', '')
                return '62xxxxxx' +numberStr.slice(10)
            }
        },
        getNameMask(str) {
            if (str) {
                return str.substring(0, 5) + "....";
            } else {
                return '';
            }
            
        },
        getJidOnly(jid) {
            return jid.replace('@s.whatsapp.net', '').replace('@g.us', '');
        },
        copyTestingCode () {
            let noToCopy = document.querySelector('#no-copy')
            noToCopy.setAttribute('type', 'text') 
            noToCopy.select()

            try {
                document.execCommand('copy');
                alert('Sukses copy no WA');
            } catch (err) {
                alert('Oops, unable to copy');
            }

            /* unselect the range */
            noToCopy.setAttribute('type', 'hidden')
            window.getSelection().removeAllRanges()
        },
        getMessageTemplates() {
            var url = this.BASE_URL + 'get-message-template'
            axios.get(url, {
                headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('access_token')
                }
            })
            .then(({data}) => {
                this.messageTemplates = data.data.messageTemplates;
                this.baseUrl = data.data.url;
            })
            .catch(error => {
                this.Toast.fire({
                    icon: 'error',
                    title: error
                })

            })
        },
        getLabels() {
            var url = this.BASE_URL + 'get-wa-label?jid=' + this.chat.jid
            axios.get(url, {
                headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('access_token')
                }
            })
            .then(({data}) => {
                this.labels = data.data;
            })
            .catch(error => {
                if (error.response.status === 401) {
                    this.Toast.fire({
                        icon: 'warning',
                        title: 'Silakan login kembali'
                    });
                    this.$router.push({name: 'login'})
                    localStorage.clear();
                } else {
                    this.Toast.fire({
                        icon: 'error',
                        title: error
                    });
                }
            })
        },
        setLabel(labelId) {
            var url = this.BASE_URL + 'set-wa-label';
            this.waLabel.jid = this.chat.jid;
            this.waLabel.labelId = labelId

            axios.post(url, this.waLabel, {
                headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('access_token')
                }
            })
            .then(({data}) => {
                this.labels = data.data;
            })
            .catch(error => {
                this.Toast.fire({
                    icon: 'error',
                    title: error
                })

            })
        },
        getAssignUser() {
            this.isAssigned = false;
            this.userAssign.message = null;
            this.userAssign.jid = null;
            this.userAssign.assign_to = null;
            var url = this.BASE_URL + 'get-assign?jid=' + this.chat.jid
            axios.get(url, {
                headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('access_token')
                }
            })
            .then(({data}) => {
                this.teamUser = data.data.team;
                if (data.data.assign.length > 0) {
                    this.isAssigned = true;
                    this.userAssign.message = data.data.assign[0].message;
                    this.userAssign.assign_to = data.data.assign[0].assign_to_id
                }
            })
            .catch(error => {
                if (error.response.status === 401) {
                    this.Toast.fire({
                        icon: 'warning',
                        title: 'Silakan login kembali'
                    });
                    this.$router.push({name: 'login'})
                    localStorage.clear();
                } else {
                    this.Toast.fire({
                        icon: 'error',
                        title: error
                    });
                }
            })
        },
        setAssign() {
            var url = this.BASE_URL + 'set-assign';
            this.userAssign.jid = this.chat.jid;
            
            axios.post(url, this.userAssign, {
                headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('access_token')
                }
            })
            .then(({data}) => {
                this.assignUser = data.data.team;
                if (data.data.assign.length > 0) {
                    this.isAssigned = true;
                }
                
            })
            .catch(error => {
                this.Toast.fire({
                    icon: 'error',
                    title: error
                })

            })
        },
        getAllLabels() {
            var url = this.BASE_URL + 'get-label'
            axios.get(url, {
                headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('access_token')
                }
            })
            .then(({data}) => {
                this.allLabels = data.data

            })
            .catch(error => {
                if (error.response.status === 401) {
                    this.Toast.fire({
                        icon: 'warning',
                        title: 'Silakan login kembali'
                    });
                    this.$router.push({name: 'login'})
                    localStorage.clear();
                } else {
                    this.Toast.fire({
                        icon: 'error',
                        title: error
                    });
                }

            })
        },
        getMessage(jid) {
            // clearInterval( this.getMessageInterval )
            // this.getMessageInterval = setInterval(() => {
                this.chat.jid = jid;
                let self = this;
                self.messages = [];
                var url = this.BASE_URL + 'get-message';
                axios.post(url, this.chat, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': localStorage.getItem('access_token')
                    }
                })
                .then(async ({data}) => {
                    self.chat = data.data.chat[0];
                    self.chat.label = this.getNumberMask(jid);
                    try {
                        let messageArray = [];
                        await data.data.messages.forEach( async (obj) => {
                            var sender = null;
                            if (obj.message) {
                                if (obj.participant) {
                                    sender = this.getNumber(obj.participant)
                                } else {
                                    sender = this.chat.name
                                }
                                const data = this.getTextMessage(obj);
                                obj.type = this.getTypeMessage(obj);
                                obj.text = data.text;
                                obj.quotedMessage = data.quotedMessage;
                                obj.sender = sender;
                                obj.timestamp =  moment(moment.unix(obj.messageTimestamp).format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm').fromNow();
                                obj.t = obj.messageTimestamp;

                                messageArray.push(obj)
                            }
                        })
                        self.messages = messageArray.sort((a, b) => a.messageTimestamp - b.messageTimestamp);
                        
                        this.cursor = data.cursor
                        
                        this.getMessageTemplates();
                        this.isChatHide = true;
                        this.isDetailHide = true;
                        this.isMessageHide = false;
                        this.getLabels();
                        this.getAssignUser();
                    } catch(err) {
                        console.log('salah atas :' +err)
                    }
                    
                    
                }).catch(error => {
                        console.log('menampilkan :'+error)
                })
            // }, 3000);
        },
        inputMessage(message, media_url) {
            console.log(media_url)
            if (this.form.message == null) {
                this.form.message = message
            } else {
                this.form.message += message
            }

            if (media_url !== null) {
                this.form.urlMedia = media_url;
                this.fileName = 'Ada file lampiran';
                this.form.fileName = media_url.replace('/files/upload/image/', '').replace('/files/upload/pdf/', '');
                this.form.typeMedia = 'image';
            }
        },
        sendMessage() {
            let url = this.BASE_URL + 'send-message';
            if (!this.chat.jid.includes('@g.us')) {
                
                this.form.to = this.getNumber(this.chat.jid);
            } else {
                this.form.to = this.chat.jid;
            }
            if (this.fileName != null && this.fileUrl != null) {
                this.form.urlMedia = this.fileUrl
            }
            if (this.form.message === undefined || this.form.message === null) {
                this.form.message = '';
            }
            axios.post(url, this.form, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': localStorage.getItem('access_token')
                }
            })
            .then( async ({data}) => {
                if (data.success) {
                    this.getMessage(this.chat.jid);
                    this.file = null;
                    this.fileName = null;
                    this.fileUrl = null;
                    this.form.urlMedia = null;
                    this.cursor = data.cursor;
                    this.form.message = "";
                    this.fileName = null;
                } else {
                    this.Toast.fire({
                        icon: 'warning',
                        title: data.message
                    })
                }
                
            })
            .catch(error => {
                    console.log(error)
            })
        },
        getTypeMessage(data) {
            let type = null;
            const obj = JSON.parse(data.message);
            try {
                if (obj.conversation) {
                    type = 'TEXT';
                } else if (obj.extendedTextMessage) {
                    type = 'TEXT';
                } else if (obj.imageMessage) {
                    type = 'IMAGE';
                } else if (obj.stickerMessage) {
                    type = 'STICKER';
                } else if (obj.videoMessage) {
                    type = 'VIDEO';
                } else if (obj.documentMessage) {
                    type = 'DOCUMENT'
                } else if (obj.protocolMessage) {
                    type = 'Deleted Message'
                } else {
                    type = 'UNKNOWN';
                }
                return type;
            } catch(err) {
                console.log('salah : '+err)
            }
            
        },
        getTextMessage(obj) {
            var text = null;
            var quotedMessage = null;
            const data = new Object();
            try {
                const objectMessage = JSON.parse(obj.message);
                if (objectMessage.conversation) {
                    text = objectMessage.conversation;
                } else if (objectMessage.extendedTextMessage) {
                   
                    const extended = objectMessage.extendedTextMessage;
                    try {
                        if (extended.contextInfo) {
                            quotedMessage = this.getQuotedMessage(extended.contextInfo);
                             
                        }
                        text = extended.text;
                    } catch(err) {
                        quotedMessage = null;
                    }
                    text = extended.text;
                } else if (objectMessage.imageMessage) {
                    if (objectMessage.imageMessage.caption != undefined) {
                        text = objectMessage.imageMessage.caption;
                    } else {
                        text = null;
                    }
                } else if (objectMessage.stickerMessage) {
                    text = null;
                } else if (objectMessage.videoMessage) {
                    text = null;
                } else if (objectMessage.documentMessage) {
                    text = null;
                } else {
                    text = null;
                }
                data.text = text;
                data.quotedMessage = quotedMessage;
                return data;        
            } catch(err) {
                console.log('salah : '+err)
            }
        },
        getQuotedMessage(obj) {
            var data = new Object();
            try {
                if (obj) {
                    if (obj.quotedMessage) {
                        if (obj.quotedMessage.conversation) {
                            data.type = 'TEXT';
                            data.quote_text = obj.quotedMessage.conversation;
                            data.id = obj.stanzaId;
                        } else if (obj.quotedMessage.imageMessage) {
                            data.type = 'IMAGE';
                            data.quote_text = obj.quotedMessage.imageMessage.caption
                            data.id = obj.stanzaId;
                        } else if (obj.quotedMessage.extendedTextMessage) {
                            data.type = 'TEXT';
                            data.quote_text = obj.quotedMessage.extendedTextMessage.text;
                            data.id = obj.stanzaId;
                        } else {
                            data = null;
                        }
                    } else {
                        data = null;
                    }
                }
                return data;
            } catch(err) {
                console.log('salah :'+err);
            }
        },
        onFileChange(e){
                this.file = e.target.files[0];
                let formData = new FormData();
                formData.append('file', this.file);
                var url = this.BASE_URL + 'upload-file'

                axios.post(url, formData, {
                    headers: {
                        'content-type': 'multipart/form-data',
                        'x-access-token': localStorage.getItem('access_token')
                    }
                })
                .then(({data}) => {
                    if (data.success) {
                        this.fileName =  data.data.originalName;
                        this.form.fileName = data.data.originalName;
                        this.fileUrl = data.data.path + data.data.fileName;
                        this.form.typeMedia = data.data.fileType;
                    } else {
                        this.Toast.fire({
                            icon: 'warning',
                            title: data.message
                        })
                    }
                    
                    
                })
                .catch(error => {
                    console.log(error)
                    
                });
        },
        downloadMedia(messageId, jid, type, isQuotedMessage, messageQuoteId) {
                if (isQuotedMessage) {
                    this.download.messageId = messageQuoteId
                } else {
                    this.download.messageId = messageId
                }
                this.download.jid = jid
                var url = this.BASE_URL + 'download-file/'
                axios.post(url, this.download, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': localStorage.getItem('access_token')
                    }
                })
                .then(({data}) => {
                    if (data.success) {
                        if (type === 'DOCUMENT') {
                            this.downloadPDF(data.data.fileUrl, data.data.name)
                        } else {
                            var arr = []
                            this.messages.forEach((obj) => {
                                if (obj.key_id == messageId) {
                                    if (isQuotedMessage) {
                                        obj.quoted_media_path = data.data.fileUrl;
                                    } else {
                                        obj.media_path = data.data.fileUrl;
                                    }
                                    
                                }
                                arr.push(obj);
                            })
                            this.messages = arr;
                        }       
                    } else {
                        this.Toast.fire({
                            icon: 'warning',
                            title: data.message
                        })
                    }
                    
                })
                .catch(error => {
                    this.Toast.fire({
                        icon: 'error',
                        title: error
                    })
                })  
        },
        async downloadPDF(url, label) {
            const response = await axios.get(url, { responseType: "blob" });
            const blob = new Blob([response.data], { type: "application/pdf" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = label;
            link.click();
            URL.revokeObjectURL(link.href);
        },
        openDetailChat() {
            this.isChatHide = true;
            this.isMessageHide = true;
            this.isDetailHide = false;
        },
        closeDetailChat() {
            this.isChatHide = true;
            this.isMessageHide = false;
            this.isDetailHide = true;
        },
        setFilterLabel(id) {
            var x = 0;
            
            this.allLabels.forEach((obj) => {
                if (obj.id == id) {
                    this.allLabels.splice(x, 1);
                    this.filterLabel.push(obj);
                    this.filterLabelParam.push(obj.id);
                }
                x++;
            })
            this.filter(this.filterLabelParam);
        },
        removeFilterLabel(id) {
            var x = 0;
            this.filterLabel.forEach((obj) => {
                if (obj.id == id) {
                    this.allLabels.push(obj);
                    this.filterLabel.splice(x, 1);
                }
                x++;
            });
            var y = 0;
            this.filterLabelParam.forEach((labelId) => {
                if (labelId == id) {
                    this.filterLabelParam.splice(y, 1);
                }
                y++;
            })
            if (this.filterLabelParam.length == 0) {
                this.chats = [];
                this.getDataFirstTime(true);
            } else {
                this.filter();          
            }
            
        },
        clearFilter() {
            this.filterLabel = [];
            this.getAllLabels();
            this.filterLabelParam = [];
            this.getDataFirstTime(true);
            this.chats = [];
        },
        filter() {
            clearInterval( this.interval )
            var url = this.BASE_URL + 'filter-wa-label?labelIds=' + JSON.stringify(this.filterLabelParam);
            axios.get(url, {
                headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('access_token')
                }
            })
            .then(({data}) => {
                let self = this
                self.chats = []
                data.data.forEach((obj) => {
                    obj.t = moment(moment.unix(obj.t).format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm').fromNow();
                    if (obj.jid != 'status@broadcast') {
                        self.chats.push(obj)
                    }
                })
                
            })
            .catch(error => {
                if (error.response.status === 401) {
                    this.Toast.fire({
                        icon: 'warning',
                        title: 'Silakan login kembali'
                    });
                    this.$router.push({name: 'login'})
                    localStorage.clear();
                } else {
                    this.Toast.fire({
                        icon: 'error',
                        title: error
                    });
                }

            })
        },
        toogleDialogEmoji() {
            this.showDialog = !this.showDialog;
        },
        onSelectEmoji(emoji) {
            if (this.form.message == null) {
                this.form.message = emoji.data;
            } else {
                this.form.message += emoji.data;
            }
            this.showDialog = false;
        },
        deleteMessage(messageId, t) {
            if (parseInt(moment().unix()) - parseInt(t) < 3600) {
                if(confirm('Yakin menghapus pesan ?')) {
                    var url = this.BASE_URL + 'delete-message'
                    this.messageAction.jid = this.chat.jid;
                    this.messageAction.message_id = messageId
                    axios.post(url, this.messageAction, {
                        headers: {
                            'Content-Type': 'application/json',
                            'x-access-token': localStorage.getItem('access_token')
                        }
                    })
                    .then( async ({data}) => {
                        if (data.success) {
                            this.getMessage(this.chat.jid);
                            this.file = null;
                            this.fileName = null;
                            this.fileUrl = null;
                            this.form.urlMedia = null;
                            this.cursor = data.cursor;
                            this.form.message = "";
                            this.fileName = null;
                        }
                    })
                    .catch(error => {
                            alert(error);
                    })
                }
            } else {
                alert('Tidak dapat menghapus pesan, sudah lebih dari 1 jam');
            }
        },
        setUser(messageId) {
            if (confirm('Yakin menandai pesan ini sebagai pesan yang kamu kirim ?')) {
                var url = this.BASE_URL + 'set-user-message'
                    this.messageAction.message_id = messageId
                    axios.post(url, this.messageAction, {
                        headers: {
                            'Content-Type': 'application/json',
                            'x-access-token': localStorage.getItem('access_token')
                        }
                    })
                    .then( async ({data}) => {
                        if (data.success) {
                            this.Toast.fire({
                                icon: 'success',
                                title: 'Sukses menandai chat'
                            })
                        }
                    })
                    .catch(error => {
                            alert(error);
                    })
            }
        },
        search() {
            if (this.isSearch) {
                this.chats = [];
                this.isSearch = false;
                this.getDataFirstTime(true);
                this.formSearch.keyword = null;
                this.title_chat = "Live Chat";
            } else {
                clearInterval( this.interval )
                this.chats = [];
                var url = this.BASE_URL + 'search-chat'
                axios.post(url, this.formSearch, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': localStorage.getItem('access_token')
                    }
                })
                .then(({data}) => {
                    let self = this
                    if (data.data.length > 0) {
                        data.data.forEach((obj) => {
                            this.isSearch = true;
                            this.title_chat = 'Search : '+this.formSearch.keyword.substring(0, 10)+"...";
                            obj.t = moment(moment.unix(obj.t).format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm').fromNow();
                            if (obj.jid != 'status@broadcast') {
                                self.chats.push(obj)
                            }
                        })
                    } else {
                        this.isSearch = true;
                        this.Toast.fire({
                            icon: 'warning',
                            title: 'Pencarian tidak ditemukan.'
                        })
                    }
                    
                    
                })
                .catch(error => {
                    this.Toast.fire({
                        icon: 'error',
                        title: error
                    })

                })
            }
            
        },
        readMessage(jid) {
            const param = {
                jid : jid
            }
            const url = this.BASE_URL + 'read-chat';
            axios.post(url, param , {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': localStorage.getItem('access_token')
                }
            })
            .then( async ({data}) => {
                if (data.success) {
                    this.Toast.fire({
                        icon: 'success',
                        title: 'Sukses Read Chat'
                    })
                }
                
                
            })
            .catch(error => {
                    console.log(error)
            })
        }
    },
    mount () {
        this.show()
    }
}
</script>