<template>
<div class="app-main__inner">
    <div class="app-page-title">
        <div class="page-title-wrapper">
            <div class="page-title-heading">
                <div class="page-title-icon">
                    <i class="pe-7s-camera icon-gradient bg-ripe-malin"></i>
                </div>
                <div>Buat Template Pesan
                    <div class="page-title-subheading">Anda dapat menyimpan template pesan</div>
                </div>
            </div>

        </div>
    </div>  
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-body">
                    <form @submit.prevent="createMessageTemplate" enctype="multipart/form-data">
                        <div class="position-relative form-group">
                            <label for="exampleEmail" class="">Judul</label>
                            <input type="text" class="form-control" v-model="form.title">
                        </div>

                        <div class="position-relative form-group">
                            <label for="exampleText" class="">Message</label>
                            <textarea rows="5" class="form-control" v-model="form.message"></textarea>
                        </div>
                        <div class="position-relative form-group">
                            <label for="exampleFile" class="">File</label>
                            <input type="file" ref="file" class="form-control-file" v-on:change="onFileChange">
                                <small class="form-text text-muted">{{fileName}}</small>
                        </div>

                        <button class="mt-1 btn btn-primary" type="submit">Simpan</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
      return {
        value: '',
        size: 250,
        isConnect: false,
        isInit: true,
        messageTemplates: [],
        form: {
            title: null,
            message: null,
            media_url: null
        },
        file: null,
        fileName: null
      }
    },
    created() {
        const storeToken = localStorage.getItem('access_token');
        if (!storeToken){
            this.$router.push({name: 'login'})
        }
    },
    methods: {
        createMessageTemplate() {
            var url = this.BASE_URL + 'store-message-template'
            axios.post(url, this.form, {
                headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('access_token')
                }
            })
            .then(({data}) => {
                if (data.success) {
                    this.Toast.fire({
                        icon: 'success',
                        title: 'Sukses simpan'
                    })
                    this.$router.push({name: 'message-template'})
                } else {
                    this.Toast.fire({
                        icon: 'error',
                        title: 'Error simpan message template'
                    })
                }

            })
            .catch(error => {
                this.Toast.fire({
                    icon: 'error',
                    title: error
                })

            })
        },
        onFileChange(e){
                console.log(e.target.files[0]);
                this.file = e.target.files[0];
                let formData = new FormData();
                formData.append('file', this.file);
                var url = this.BASE_URL + 'upload-file'

                axios.post(url, formData, {
                    headers: {
                        'content-type': 'multipart/form-data',
                        'x-access-token': localStorage.getItem('access_token')
                    }
                })
                .then(({data}) => {
                    console.log(data)
                    this.fileName = 'Success upload :' + data.data.originalName;
                    this.form.media_url = data.data.path + data.data.fileName
                    // Toast.fire({
                    //     icon: 'success',
                    //     title: 'Sukses upload'
                    // })
                    
                })
                .catch(error => {
                    console.log(error)
                    // Toast.fire({
                    //     icon: 'error',
                    //     title: error.response.data.message
                    // })
                });
            },
    }
}
</script>