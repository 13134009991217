import Vue from "vue"
import Router from "vue-router"
import Main from "./components/Main.vue"
import WhatsappAccount from "./views/WhatsappAccount.vue"
import MessageTemplate from "./views/MessageTemplate.vue"
import MessageTemplateCreate from "./views/MessageTemplateCreate.vue"
import MessageTemplateUpdate from "./views/MessageTemplateUpdate.vue"
import Label from "./views/Label.vue"
import LabelCreate from "./views/LabelCreate.vue"
import LabelUpdate from "./views/LabelUpdate.vue"
import Chat from "./views/Chat.vue"
import Message from "./views/Message.vue"
import HelloWorld from "./views/HelloWorld.vue"
import Login from "./components/Login.vue"
import Register from "./components/Register.vue"
import Team from "./views/Team.vue"
import TeamCreate from "./views/TeamCreate.vue"
import TeamUpdate from "./views/TeamUpdate.vue"
import Analytic from "./views/Analytic.vue"

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/",
      name: "home",
      component: Main,
      props: true,
      children: [
        {
          path: "/chat",
          name: "chat",
          component: Chat
        },
        {
          path: "/hello",
          name: "",
          component: HelloWorld,
        },
        {
          path: "/whatsapp-account",
          name: "whatsapp-account",
          component: WhatsappAccount
        },
        {
          path: "/message-template",
          name: "message-template",
          component: MessageTemplate
        },
        {
          path: "/message-template-create",
          name: "message-template-create",
          component: MessageTemplateCreate
        },
        {
          path: "/edit-message-template/:id",
          name: "edit-message-template",
          component: MessageTemplateUpdate
        },
        {
          path: "/label",
          name: "label",
          component: Label
        },
        {
          path: "/label-create",
          name: "label-create",
          component: LabelCreate
        },
        {
          path: "/edit-label/:id",
          name: "edit-label",
          component: LabelUpdate
        },
        {
          path: "/message/:jid",
          name: "message",
          component: Message
        },
        {
          path: "/team",
          name: "team",
          component: Team
        },
        {
          path: "/team-create",
          name: "team-create",
          component: TeamCreate
        },
        {
          path: "/team-update/:id",
          name: "team-update",
          component: TeamUpdate
        },
        {
          path: "/analytic",
          name: "analytic",
          component: Analytic
        }
      ]
    },
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/register",
      name: "register",
      component: Register
    },
    {
      path: '*',
      name: 'catchAll',
      component: Chat
    }
  ]
});

export default router;
