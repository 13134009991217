<template>
<div class="app-main__inner">
    <div class="row">
        <div class="col-sm-12 col-lg-8">
            <div class="card-hover-shadow-2x mb-3 card">
                <div class="card-header-tab card-header">
                    <div class="card-header-title font-size-lg text-capitalize font-weight-normal" v-if="chat"><i @click="back()" class="fa fa-fw mr-4" aria-hidden="true" title="Copy to use arrow-left"></i>{{chat.name}}</div>
                    <div class="btn-actions-pane-right text-capitalize actions-icon-btn">
                        <input type="hidden" id="no-copy" v-if="chat" :value="getNumber(chat.jid)">
                        <button @click="copyTestingCode()" type="button" aria-haspopup="true" aria-expanded="false" class="border-0 btn-transition btn btn-link">
                            <i class="fas fa-copy"></i>
                        </button>
                        <div class="btn-group dropdown">
                            <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="border-0 btn-transition btn btn-link">
                                <i class="fas fa-tags"></i>
                            </button>
                            <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu dropdown-menu-right scroll-area-lg">
                                <h6 tabindex="-1" class="dropdown-header">Set Label</h6>
                                <button @click="setLabel(item.id)" type="button" tabindex="0" class="dropdown-item" v-for="item in labels" :key="item.id">{{item.name}} <i class="fa fa-check ml-2" v-if="item.isSelected === 1"></i></button>
                            </div>
                        </div>
                        <div class="btn-group dropdown">
                            <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="border-0 btn-transition btn btn-link">
                                <i class="fas fa-clipboard-list"></i>
                            </button>
                            <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu dropdown-menu-right scroll-area-lg">
                                <h6 tabindex="-1" class="dropdown-header">Template Pesan</h6>
                                <button @click="inputMessage(item.message, item.media_url)" type="button" tabindex="0" class="dropdown-item" v-for="item in messageTemplates" :key="item.id">{{item.title}}<i class="fas fa-paperclip ml-2" v-show="item.media_url"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="scroll-area-lg">
                    <div class="scrollbar-container">
                        <div class="p-2">
                            <div class="chat-wrapper">
                                <div v-for="item in messages" :key="item.key.id">
                                    <div class="float-right" v-if="item.key.fromMe" style="clear: both;">
                                        <div class="chat-box-wrapper chat-box-wrapper-right">
                                            <div>
                                                <div class="chat-box" style="white-space: pre-line">
                                                    <div>
                                                        <a :href="item.media_path" download="download" v-show="item.media_path && item.type == 'DOCUMENT'"></a>
                                                        <img :src="item.media_path " style="width: 250px;height: 100%;object-fit: cover;" v-show="item.media_path">
                                                        <button class="mb-2 mr-2 btn-transition btn btn-outline-secondary" v-show="item.type != 'TEXT' && item.media_path == null" @click="downloadMedia(item.key.id, item.key.remoteJid, item.type)">Download {{item.type}}
                                                        </button>
                                                        <p style="width:250px; word-wrap:break-word;">{{item.text}}</p>
                                                    </div>
                                                </div>
                                                <small class="opacity-6">
                                                    <i class="fa fa-calendar-alt mr-1"></i>
                                                    {{item.t}}
                                                </small>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="chat-box-wrapper" v-else>
                                        <div>
                                            <div class="chat-box" style="white-space: pre-line">
                                                <div>
                                                    <a :href="item.media_path" download="download" v-show="item.media_path && item.type == 'DOCUMENT'"></a>
                                                    <img :src="item.media_path " style="width: 250px;height: 50%;object-fit: contain;" v-show="item.media_path && item.type == 'IMAGE'">
                                                    <button class="mb-2 mr-2 btn-transition btn btn-outline-secondary" v-show="item.type != 'TEXT' && item.media_path == null" @click="downloadMedia(item.key.id, item.key.remoteJid)">Download {{item.type}}
                                                    </button>
                                                    <p style="width:250px; word-wrap:break-word;">{{item.text}}</p>
                                                </div>
                                            </div>
                                            <small class="opacity-6">
                                                    <i class="fa fa-calendar-alt mr-1"></i>
                                                    {{item.t}} | {{item.sender}}
                                            </small>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer" v-show="fileName">
                    <span class="text-success">Success upload : {{fileName}}</span>
                </div>
                <div class="card-footer">
                    <div>
                        <i class="fa fa-fw mr-2" aria-hidden="true" title="Copy to use paperclip" @click="$refs.file.click()"></i>
                        <input type="file" ref="file" style="display: none" v-on:change="onFileChange">
                        <i class="fa fa-fw mt-3 mr-2" aria-hidden="true" title="Copy to use smile-o"></i>
                    </div>
                    <textarea name="text" id="exampleText" class="form-control" rows="2" v-model=form.message></textarea>
                    <button class="btn btn-success ml-3" @click="sendMessage()">Send</button>
                </div>        
            </div>
        </div>                
    </div>
</div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
    data() {
      return {
        chats: [],
        isConnect: false,
        isInit: true,
        messages: [],
        jid: null,
        param: {
            jid: null
        },
        chat: null,
        form : {
            to: null,
            message: null,
            urlMedia: null,
            typeMedia: null,
            fileName: null
        },
        file: null,
        fileName: null,
        fileUrl: null,
        messageTemplates: [],
        download: {
            messageId: null,
            jid: null
        },
        labels: [],
        waLabel: {
            jid: null,
            labelId: null
        },
        baseUrl: null
      }
    },
    created() {
        const storeToken = localStorage.getItem('access_token');
        if (!storeToken){
            this.$router.push({name: 'login'})
        } else {
            this.jid = this.$route.params.jid
            this.getStatus()
        }
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        copyTestingCode () {
            let noToCopy = document.querySelector('#no-copy')
            noToCopy.setAttribute('type', 'text') 
            noToCopy.select()

            try {
                document.execCommand('copy');
               
                alert('Sukses copy no WA');
            } catch (err) {
                alert('Oops, unable to copy');
            }

            /* unselect the range */
            noToCopy.setAttribute('type', 'hidden')
            window.getSelection().removeAllRanges()
        },
        getStatus() {
            var url = this.BASE_URL + 'connect'
            axios.get(url, {
                headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('access_token')
                }
            })
            .then(({data}) => {
                if (data.data.status == "Connected") {
                    this.getMessage();
                    this.getMessageTemplates();
                    this.getLabels();
                    this.value = data.data.qr;
                } else {
                    this.Toast.fire({
                        icon: 'warning',
                        title: 'Menunggu Koneksi WhatsApp'
                    })
                    this.$router.push({name: 'whatsapp-account'});
                }
            })
            .catch(error => {
                this.Toast.fire({
                    icon: 'error',
                    title: error
                })

            })
        },
        getMessageTemplates() {
            var url = this.BASE_URL + 'get-message-template'
            axios.get(url, {
                headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('access_token')
                }
            })
            .then(({data}) => {
                this.messageTemplates = data.data.messageTemplates;
                this.baseUrl = data.data.url;
            })
            .catch(error => {
                this.Toast.fire({
                    icon: 'error',
                    title: error
                })

            })
        },
         getLabels() {
            var url = this.BASE_URL + 'get-wa-label?jid=' + this.param.jid
            axios.get(url, {
                headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('access_token')
                }
            })
            .then(({data}) => {
                this.labels = data.data;
            })
            .catch(error => {
                if (error.response.status === 401) {
                    this.Toast.fire({
                        icon: 'warning',
                        title: 'Silakan login kembali'
                    });
                    this.$router.push({name: 'login'})
                    localStorage.clear();
                } else {
                    this.Toast.fire({
                        icon: 'error',
                        title: error
                    });
                }

            })
        },
        setLabel(labelId) {
            var url = this.BASE_URL + 'set-wa-label';
            this.waLabel.jid = this.jid;
            this.waLabel.labelId = labelId

            axios.post(url, this.waLabel, {
                headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('access_token')
                }
            })
            .then(({data}) => {
                this.labels = data.data;
            })
            .catch(error => {
                this.Toast.fire({
                    icon: 'error',
                    title: error
                })

            })
        },
        getMessage() {
            this.param.jid = this.$route.params.jid
            var url = this.BASE_URL + 'get-message'
            axios.post(url, this.param, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': localStorage.getItem('access_token')
                }
            })
            .then(({data}) => {
                let self = this;
                this.messages = [];
                self.chat = data.data.chat[0];
                data.data.messages.messages.forEach((obj) => {
                    var sender = null;
                    if (obj.message) {

                        if (obj.participant) {
                            sender = this.getNumber(obj.participant)
                        } else {
                            sender = this.chat.name
                        }

                        obj.type = this.getTypeMessage(obj);
                        obj.text = this.getTextMessage(obj);
                        obj.sender = sender;
                        obj.t = moment(moment.unix(obj.messageTimestamp).format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm').fromNow();

                        self.messages.push(obj)
                    }

                })
                this.cursor = data.cursor
            })
            .catch()
        },
        inputMessage(message, media_url) {
            if (this.form.message == null) {
                this.form.message = message
            } else {
                this.form.message += message
            }

            if (media_url !== null) {
                this.form.urlMedia = this.baseUrl+media_url
                this.fileName = 'Ada file lampiran'
            }
        },
        sendMessage() {
            var url = this.BASE_URL + 'send-message'
            this.form.to = this.getNumber(this.chat.jid)
            if (this.fileName != null && this.fileUrl != null) {
                this.form.urlMedia = this.fileUrl
            }
            if (this.form.message === undefined || this.form.message === null) {
                this.form.message = '';
            }
            axios.post(url, this.form, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': localStorage.getItem('access_token')
                }
            })
            .then(({data}) => {
                let self = this
                this.messages = []
                data.data.messages.messages.forEach((obj) => {
                    console.log(obj)
                    var sender = null;
                    if (obj.message) {
                        if (obj.participant) {
                            sender = this.getNumber(obj.participant)
                        } else {
                            sender = ''
                        }
                        obj.type = this.getTypeMessage(obj);
                        obj.text = this.getTextMessage(obj);
                        obj.sender = sender;
                        obj.t = moment(moment.unix(obj.messageTimestamp).format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm').fromNow();
                        self.messages.push(obj)
                    }     
                })
                this.file = null;
                this.fileName = null;
                this.fileUrl = null;
                this.form.urlMedia = null;
                this.cursor = data.cursor;
                this.form.message = "";
                this.fileName = null;
            })
            .catch(error => {
                    console.log(error)
            })
        },
        getTypeMessage(obj) {
            var type = null;
            if (obj.message.conversation) {
                type = 'TEXT';
            } else if (obj.message.extendedTextMessage) {
                type = 'TEXT';
            } else if (obj.message.imageMessage) {
                type = 'IMAGE';
            } else if (obj.message.stickerMessage) {
                type = 'STICKER';
            } else if (obj.message.videoMessage) {
                type = 'VIDEO';
            } else if (obj.message.documentMessage) {
                type = 'DOCUMENT'
            } else {
                type = 'UNKNOWN';
            }

            return type;
        },
        getTextMessage(obj) {
            var text = null;
            if (obj.message.conversation) {
                text = obj.message.conversation;
            } else if (obj.message.extendedTextMessage) {
                text = obj.message.extendedTextMessage.text
            } else if (obj.message.imageMessage) {
                text = obj.message.imageMessage.caption;
            } else if (obj.message.stickerMessage) {
                text = null;
            } else if (obj.message.videoMessage) {
                text = null;
            } else if (obj.message.documentMessage) {
                text = null;
            } else {
                text = null;
            }

            return text;
            
        },
        getNumber(str) {
            if (str.includes('@g.us')) {
                return 'GROUP';
            } else {
                return str.replace('@s.whatsapp.net', '')
            }
        },
        onFileChange(e){
                console.log(e.target.files[0]);
                this.file = e.target.files[0];
                let formData = new FormData();
                formData.append('file', this.file);
                var url = this.BASE_URL + 'upload-file'

                axios.post(url, formData, {
                    headers: {
                        'content-type': 'multipart/form-data',
                        'x-access-token': localStorage.getItem('access_token')
                    }
                })
                .then(({data}) => {
                    console.log(data)
                    if (data.success) {
                        this.fileName =  data.data.originalName;
                        this.form.fileName = data.data.originalName;
                        this.fileUrl = data.data.fileUrl;
                        this.form.typeMedia = data.data.fileType;
                    } else {
                        this.Toast.fire({
                            icon: 'warning',
                            title: data.message
                        })
                    }
                    
                    
                })
                .catch(error => {
                    console.log(error)
                    
                });
        },
        downloadMedia(messageId, jid, type) {
                this.download.messageId = messageId
                this.download.jid = jid
                var url = this.BASE_URL + 'download-file/'
                axios.post(url, this.download, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': localStorage.getItem('access_token')
                    }
                })
                .then(({data}) => {
                    if (data.success) {
                        if (type === 'DOCUMENT') {
                            this.downloadPDF(data.data.fileUrl, data.data.name)
                        } else {
                            var arr = []
                            this.messages.forEach((obj) => {
                                if (obj.key.id == messageId) {
                                    obj.media_path = data.data.fileUrl
                                }
                                arr.push(obj)
                            })
                            this.messages = arr;
                        }       
                    } else {
                        this.Toast.fire({
                            icon: 'warning',
                            title: data.message
                        })
                    }
                    
                })
                .catch(error => {
                    this.Toast.fire({
                        icon: 'error',
                        title: error.response.data.message
                    })
                })  
        },
        async downloadPDF(url, label) {
            const response = await axios.get(url, { responseType: "blob" });
            const blob = new Blob([response.data], { type: "application/pdf" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = label;
            link.click();
            URL.revokeObjectURL(link.href);
        },
    }
}
</script>