import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Swal from 'sweetalert2';
import VueChatScroll from 'vue-chat-scroll';
import vmodal from 'vue-js-modal'

Vue.config.productionTip = false;

window.axios = require('axios');

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });
Vue.prototype.Toast = Toast

Vue.prototype.Swal = Swal



// BASE URL

// const BASE_URL = 'http://localhost:3000/api/'
// const BASE_URL = 'https://cek-wa.com/mirror-api/api/'
const BASE_URL = 'https://chat.dewakoding.com/api/api/'
Vue.prototype.BASE_URL = BASE_URL
Vue.use(VueChatScroll)
Vue.use(vmodal)
new Vue({
  VueChatScroll,
  vmodal,
  router,
  render: h => h(App)
}).$mount("#app");

