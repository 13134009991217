
import { Line } from 'vue-chartjs'
import axios from 'axios'
export default {
    extends: Line,
    name: "LineChart",
    props:['data','options'],
    mounted () {
        var url = this.BASE_URL + 'get-analytics'
        axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('access_token')
            }
        })
        .then(({data}) => {
            if (data.success) {
                let labels = [];
                let dataTable = [];
                data.data.chart.forEach((obj) => {
                    labels.push(obj.tanggal);
                    dataTable.push(obj.jumlah);
                });
                this.renderChart({
                    labels: labels,
                    datasets: [
                        {
                            label: 'Jumlah Chat Masuk Menurut Tanggal',
                            backgroundColor: '#78c8c0',
                            data: dataTable
                        }
                    ]
                }, {responsive: true, maintainAspectRatio: false})
            }
        })
        .catch(error => {
            console.log('aufar: '+error);
        })
    }
}