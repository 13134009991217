<template>
<div class="app-main__inner">
    <div class="app-page-title">
        <div class="page-title-wrapper">
            <div class="page-title-heading">
                <div class="page-title-icon">
                    <i class="pe-7s-users icon-gradient bg-ripe-malin"></i>
                </div>
                <div>Analytics
                    <div class="page-title-subheading">Analytics WhatsApp Anda</div>
                </div>
            </div>

        </div>
    </div>  
    <div class="row">
        <div class="col-lg-6 col-xl-4">
            <div class="card mb-3 widget-content">
                <div class="widget-content-wrapper">
                    <div class="widget-content-left">
                        <div class="widget-heading">Total Kontak</div>
                        <div class="widget-subheading">sejak memakai cek-wa mirror</div>
                    </div>
                    <div class="widget-content-right">
                        <div class="widget-numbers text-success"><span>{{totalChat}}</span></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-xl-4">
            <div class="card mb-3 widget-content">
                <div class="widget-content-wrapper">
                    <div class="widget-content-left">
                        <div class="widget-heading">Total Grup</div>
                        <div class="widget-subheading">sejak memakai cek-wa mirror</div>
                    </div>
                    <div class="widget-content-right">
                        <div class="widget-numbers text-primary"><span>{{totalGroup}}</span></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-xl-4">
            <div class="card mb-3 widget-content">
                <div class="widget-content-wrapper">
                    <div class="widget-content-left">
                        <div class="widget-heading">Pesan belum dibuka</div>
                        <div class="widget-subheading">sejak memakai cek-wa mirror</div>
                    </div>
                    <div class="widget-content-right">
                        <div class="widget-numbers text-warning"><span>{{totalUnreadMessage}}</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="main-card mb-3 card">
                <div class="card-header-tab card-header">
                    <div class="card-header-title font-size-lg text-capitalize font-weight-normal">Jumlah Chat</div>
                   
                </div>
                <div class="card-body">
                    <LineChart></LineChart>
                </div>
            </div>
        </div>
    </div>
   
                        
</div>
</template>

<script>
import axios from 'axios';
import LineChart from '../chart/LineChart'
export default {
    components: {
        LineChart
    },
    data() {
      return {
        value: '',
        size: 250,
        isConnect: false,
        isInit: true,
        totalChat: null,
        totalGroup: null,
        totalUnreadMessage: null
      }
    },
    created() {
        const storeToken = localStorage.getItem('access_token');
        if (!storeToken){
            this.$router.push({name: 'login'})
        } else {
            // this.getTeams()
            this.getSummary()
        }
    },
    methods: {
        getSummary() {
            var url = this.BASE_URL + 'get-summary'
            axios.get(url, {
                headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('access_token')
                }
            })
            .then(({data}) => {
                this.totalChat = data.data.summary.totalChat;
                this.totalGroup = data.data.summary.totalGroup;
                this.totalUnreadMessage = data.data.summary.totalUnreadMessage;

            })
            .catch(error => {
                if (error.response.status === 401) {
                    this.Toast.fire({
                        icon: 'warning',
                        title: 'Silakan login kembali'
                    });
                    this.$router.push({name: 'login'})
                    localStorage.clear();
                } else {
                    this.Toast.fire({
                        icon: 'error',
                        title: error
                    });
                }

            })
        },
    }
}
</script>