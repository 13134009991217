<template>
<div class="app-main__inner">
    <div class="app-page-title">
        <div class="page-title-wrapper">
            <div class="page-title-heading">
                <div class="page-title-icon">
                    <i class="pe-7s-ticket icon-gradient bg-ripe-malin"></i>
                </div>
                <div>Label
                    <div class="page-title-subheading">Anda dapat menyimpan label untuk menandai Chat</div>
                </div>
            </div>

        </div>
    </div>  
    <div class="row">
        <div class="col-lg-12">
            <div class="main-card mb-3 card">
                <div class="card-header-tab card-header">
                    <div class="card-header-title font-size-lg text-capitalize font-weight-normal">Label Tersimpan</div>
                    <div class="btn-actions-pane-right text-capitalize">
                        <router-link to="/label-create"><button class="btn btn-warning">+ Label</button></router-link>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="mb-0 table table-bordered">
                            <thead>
                                <tr>
                                    <th>Label</th>
                                    <th>Edit</th>
                                    <th>Hapus</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in labels" :key="item.id">
                                    <td>{{item.name}}</td>
                                    <td>
                                        <router-link :to="{name: 'edit-label', params:{id:item.id}}" class="mb-2 mr-2 btn btn-info">Edit
                                        </router-link>
                                    </td>
                                    <td>
                                        <button class="mb-2 mr-2 btn btn-danger" @click="showDeleteDialog(item.id)">Hapus
                                        </button>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2';
export default {
    data() {
      return {
        value: '',
        size: 250,
        isConnect: false,
        isInit: true,
        labels: []
      }
    },
    created() {
        const storeToken = localStorage.getItem('access_token');
        if (!storeToken){
            this.$router.push({name: 'login'})
        } else {
            this.getLabels()
        }
    },
    methods: {
        getLabels() {
            var url = this.BASE_URL + 'get-label'
            axios.get(url, {
                headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('access_token')
                }
            })
            .then(({data}) => {
                this.labels = data.data

            })
            .catch(error => {
                if (error.response.status === 401) {
                    this.Toast.fire({
                        icon: 'warning',
                        title: 'Silakan login kembali'
                    });
                    this.$router.push({name: 'login'})
                    localStorage.clear();
                } else {
                    this.Toast.fire({
                        icon: 'error',
                        title: error
                    });
                }

            })
        },
        showDeleteDialog(id) {
            Swal.fire({
                title: 'Anda yakin?',
                text: "",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
                }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteLabel(id)
                }
            })
        },
        deleteLabel(id) {
            var url = this.BASE_URL + 'delete-label'
            const data = {
                id: id
            }
            axios.post(url, data, {
                headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('access_token')
                }
            })
            .then(({data}) => {
                if (data.success) {
                    this.getLabels();
                } else {
                    this.Toast.fire({
                        icon: 'error',
                        title: 'Error simpan message template'
                    })
                }

            })
            .catch(error => {
                this.Toast.fire({
                    icon: 'error',
                    title: error
                })

            })
        }
    }
}
</script>